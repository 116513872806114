import * as React from "react"
import { useEffect } from "react"

const NotFoundPage = () => {
  useEffect(() => {
    window.location.href = '/en/'
  }, []);

  return null;
}

export default NotFoundPage